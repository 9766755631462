<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div fill-height>
    <v-row class="flex-column" no-gutters>
      <div class="title">
        <h1>{{ userData.id ? fullName : $t('users.addEditUser.title') }}</h1>
        <v-divider />
      </div>
      <v-form ref="form" class="pa-0" @submit.prevent="submitForm">
        <v-row>
          <v-col cols="12" md="4" lg="3">
            <v-row class="white--text mt-5" no-gutters align="center" justify="center">
              <div v-if="!ldapUser" id="iiotAddEditUserOval" :class="{ 'mx-auto': !isMdAndUp }">
                <input
                  v-if="isCreateEditAllowed"
                  id="iiotAddEditUserAvatarField"
                  :disabled="!isCreateEditAllowed"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png"
                  @change="changeAvatar"
                />
                <span v-if="avatarSrc" id="iiotAddEditUserAvatar" :style="{ backgroundImage: 'url(' + avatar + ')' }" />
                <div v-if="loadingImage" class="text-center">
                  <v-icon id="loading-avatar" class="spinner white--text"> sync </v-icon>
                  <br />
                  {{ $t('users.addEditUser.loadingImage') }}
                </div>
                <div v-if="!loadingImage && !avatarSrc" class="text-center">
                  <v-icon class="white--text"> arrow_upward </v-icon>
                  <br />
                  {{ $t('users.addEditUser.uploadDragAndDropImage') }}
                </div>
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" md="8" lg="9">
            <v-row class="justify-space-between">
              <v-col cols="12" lg="6" md="6" sm="12" sx="12">
                <v-text-field
                  id="iiotAddEditUserFirstNameField"
                  ref="firstName"
                  v-model="userData.firstName"
                  :disabled="!isCreateEditAllowed"
                  :rules="[rules.required, rules.namePattern, rules.noWhitespaceAtTheBeginning, rules.nameMinLength]"
                  :label="$t('users.addEditUser.firstName')"
                  maxlength="40"
                  validate-on-blur
                />
              </v-col>
              <v-col cols="12" lg="6">
                <v-text-field
                  id="iiotAddEditUserLastNameField"
                  ref="lastName"
                  v-model="userData.lastName"
                  :disabled="!isCreateEditAllowed"
                  :rules="[rules.required, rules.namePattern, rules.noWhitespaceAtTheBeginning, rules.nameMinLength]"
                  :label="$t('users.addEditUser.lastName')"
                  maxlength="40"
                  validate-on-blur
                />
              </v-col>

              <v-col v-if="ldapUser" cols="12" lg="6">
                <v-text-field
                  id="iiotAddEditUserUsrNameField"
                  ref="usrName"
                  v-model="userData.username"
                  :rules="[rules.required, rules.namePattern, rules.noWhitespaceAtTheBeginning, rules.nameMinLength]"
                  :label="$t('users.addEditUser.usrName')"
                  :disabled="!isCreateEditAllowed"
                  maxlength="40"
                  validate-on-blur
                />
              </v-col>
              <v-col v-if="ldapUser" cols="12" lg="6">
                <v-text-field
                  id="iiotAddEditUserEmail"
                  ref="email"
                  v-model="userData.contact[0].email"
                  :rules="[rules.required, rules.namePattern, rules.nameMinLength]"
                  :label="$t('users.addEditUser.email')"
                  :disabled="!isCreateEditAllowed"
                  maxlength="40"
                  validate-on-blur
                />
              </v-col>
              <v-col v-if="!ldapUser" cols="12" lg="6">
                <v-text-field
                  v-if="userData.id"
                  id="iiotAddEditUserUsernameField"
                  ref="username"
                  v-model="userData.username"
                  :rules="[rules.required, rules.email]"
                  :label="$t('users.addEditUser.username')"
                  :disabled="!!userData.id"
                  validate-on-blur
                  type="email"
                />
                <v-text-field
                  v-else
                  id="iiotAddEditUserUsernameField"
                  ref="username"
                  v-model="userData.username"
                  :rules="[rules.required, rules.email]"
                  :label="$t('users.addEditUser.username')"
                  persistent-hint
                  validate-on-blur
                  type="email"
                >
                  <v-tooltip slot="append" bottom>
                    <template #activator="{ on }">
                      <v-icon class="cursor-help" color="primary" v-on="on"> info </v-icon>
                    </template>
                    <span>{{ $t('users.addEditUser.emailHint') }}</span>
                  </v-tooltip>
                </v-text-field>
              </v-col>
              <v-col v-if="isUpdateLoggedUser" cols="12" lg="6">
                <password-field
                  ref="currentPassword"
                  v-model="userData.currentPassword"
                  element-id="currentPasswordField"
                  :disabled="!isCreateEditAllowed"
                  :label="$t('users.addEditUser.oldPasswordLbl')"
                  :min="passwordMinLen"
                  :required="userData.newPassword.length > 0 || userData.confirmPassword.length > 0"
                />
              </v-col>
              <v-col v-if="isUpdateLoggedUser" cols="12" lg="6">
                <password-field
                  ref="newPassword"
                  v-model="userData.newPassword"
                  element-id="newPasswordField"
                  :label="$t('users.addEditUser.newPasswordLbl')"
                  :hint="$t('users.addEditUser.newPasswordHint')"
                  :disabled="!isCreateEditAllowed"
                  :min="passwordMinLen"
                  :required="userData.currentPassword.length > 0 || userData.confirmPassword.length > 0"
                  :rules="rules.newPassword"
                />
              </v-col>
              <v-col v-if="isUpdateLoggedUser" cols="12" lg="6">
                <password-field
                  ref="confirmPassword"
                  v-model="userData.confirmPassword"
                  element-id="confirmPasswordField"
                  :label="$t('users.addEditUser.confirmNewPasswordLbl')"
                  :disabled="!isCreateEditAllowed"
                  :min="passwordMinLen"
                  :required="userData.currentPassword.length > 0 || userData.newPassword.length > 0"
                  :rules="rules.confirmPassword"
                />
              </v-col>
              <v-col id="iiotAddEditUserPreferredLanguageField" cols="12" lg="6">
                <v-select
                  ref="preferredLanguage"
                  v-model="userData.preferredLanguage"
                  :disabled="!isCreateEditAllowed"
                  :items="languages"
                  :label="$t('users.addEditUser.language')"
                  :required="true"
                  item-text="value"
                  item-value="key"
                  attach
                />
              </v-col>
              <v-col id="iiotAddEditUserRolesField" cols="12" lg="6">
                <v-select
                  ref="roles"
                  v-model="userData.roles"
                  data-cy="iiotAddEditUserRolesFieldSelector"
                  :items="roles"
                  :label="$t('users.addEditUser.role')"
                  :required="true"
                  :rules="rules.roles"
                  :disabled="!isRoleAllowed"
                  item-text="prettyRoleName"
                  return-object
                  multiple
                  attach
                />
              </v-col>
              <v-col v-if="msMfaEnabled" cols="12" lg="6">
                <v-select
                  id="iiotAddEditUserMfaCheckbox"
                  v-model="userData.mfaEnabled"
                  data-cy="iiotAddEditUserMfaCheckbox"
                  :disabled="!isMfaAllowed"
                  :items="mfa"
                  :label="$t('users.addEditUser.mfa')"
                  attach
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-col class="pa-2 pl-1" offset-md="4" offset-lg="3">
              <div>
                <nerve-button
                  id="iiotAddEditUserCancel"
                  :text="$t('baseForm.cancelBtn')"
                  type-of-btn="cancel"
                  size="normal"
                  class="mr-5 ml-0"
                  @click-event="goToUsers()"
                />
                <nerve-button
                  v-if="showUpdateSaveButton"
                  id="iiotAddEditUserSave"
                  :text="userData.id ? $t('baseForm.updateBtn') : $t('baseForm.saveBtn')"
                  type-of-btn="action"
                  size="normal"
                  type="submit"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-form>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
import { NerveButton } from 'nerve-ui-components';
import PasswordField from '@/components/shared/PasswordField.vue';
import { VALIDATION_REGEX, USER_PASSWORD } from '@/constants';
import AvatarMixin from '@/mixins/avatar.mixin';

const ALLOWED_EXTENSIONS = ['jpg', 'png', 'jpeg'];
const MAX_FILE_SIZE = 5000000; // 5 MB in bytes

export default {
  components: { PasswordField, NerveButton },
  mixins: [AvatarMixin],
  data() {
    return {
      selfId: this.$store.getters['auth/authUser'].id,
      isSettingsPage: false,
      isLoggedLdapUser: false,
      ldapUser: false,
      fullName: '',
      avatarSrc: null,
      avatarFile: null,
      loadingImage: false,
      passwordMinLen: USER_PASSWORD.MIN_LENGTH,
      username: '',
      id: '',
      mfa: [
        {
          text: this.$t('users.addEditUser.mfaEnabled'),
          value: true,
          id: 'iiotAddEditUserMfaEnabledCheckbox',
        },
        {
          text: this.$t('users.addEditUser.mfaDisabled'),
          value: false,
          id: 'id="iiotAddEditUserMfaDisabledCheckbox"',
        },
      ],
    };
  },
  computed: {
    userData() {
      return this.$store.getters['users/getUserData'];
    },
    avatar() {
      return this.avatarSrc[0] === '/' ? this.getAvatarURL(this.avatarSrc) : this.avatarSrc;
    },
    rules() {
      return {
        required: (v) => !!v || this.$t('baseForm.required'),
        namePattern: (v) => VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(v) || this.$t('users.addEditUser.nameRegexMsg'),
        noWhitespaceAtTheBeginning: (v) =>
          VALIDATION_REGEX.NO_WHITESPACE_AT_THE_BEGINNING.test(v) ||
          this.$t('users.addEditUser.noWhitespaceAtTheBeginningMsg'),
        nameMinLength: (v) => v.length >= 2 || this.$t('users.addEditUser.nameMinLengthMsg'),
        email: (v) => VALIDATION_REGEX.EMAIL.test(v) || this.$t('users.addEditUser.emailRegexMsg'),
        newPassword: [
          (v) => !v || VALIDATION_REGEX.NEW_PASSWORD.test(v) || this.$t('users.addEditUser.passwordRegexMsg'),
        ],
        confirmPassword: [
          () =>
            this.userData.confirmPassword === this.userData.newPassword ||
            this.$t('users.addEditUser.passwordMatchMsg'),
        ],
        roles: [(v) => v.length > 0 || this.$t('users.addEditUser.roleRequiredMsg')],
      };
    },
    languages() {
      return Object.keys(this.$vuetify.lang.locales).map((lang) => ({
        key: `${lang}_${lang.toUpperCase()}`,
        value: this.$t(`app.langs.${lang}`),
      }));
    },
    roles() {
      return this.$store.getters['roles/getAllRoles'].filter((item) =>
        this.userData.type === 'ldap' ? item : item.type === 'local',
      );
    },
    isMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isLdapUser() {
      return this.isLoggedLdapUser || this.ldapUser;
    },
    isUpdateLoggedUser() {
      return this.userData.id === this.selfId && !this.ldapUser;
    },
    isCreateEditAllowed() {
      return (
        (!this.userData.id && !this.canAccess('UI_USER:EDIT')) ||
        (((this.userData.id && this.canAccess('UI_USER:EDIT') && !this.isSettingsPage) ||
          (this.isSettingsPage && this.canAccess('UI_USER:EDIT_PERSONAL_PROFILE'))) &&
          !this.isLoggedLdapUser &&
          !this.ldapUser) ||
        (!this.userData.id && this.canAccess('UI_USER:CREATE') && !this.isLoggedLdapUser)
      );
    },
    hasRoleEditPermission() {
      return this.canAccess('UI_USER:EDIT_ROLE');
    },
    hasMfaPermission() {
      return this.canAccess('UI_USER:MFA_CONTROL');
    },
    isRoleAllowed() {
      return !this.isSettingsPage && (this.id === 'new' || this.hasRoleEditPermission) && !this.isLdapUser;
    },
    isMfaAllowed() {
      return !this.isSettingsPage && (this.id === 'new' || this.hasMfaPermission) && !this.isLdapUser;
    },
    msMfaEnabled() {
      return !this.isLdapUser && this.$store.getters['multi-factor-authentication/isMFAEnabled'];
    },
    showUpdateSaveButton() {
      return this.isCreateEditAllowed || (this.hasRoleEditPermission && !this.isLdapUser);
    },
  },
  watch: {
    'userData.username': function (val) {
      this.userData.contact[0].contact = val.toLowerCase();
      this.userData.username = val.toLowerCase();
    },
    'userData.currentPassword': function () {
      this.$refs.form.validate();
    },
    'userData.newPassword': function () {
      this.$refs.form.validate();
    },
    'userData.confirmPassword': function () {
      this.$refs.form.validate();
    },
  },
  async mounted() {
    try {
      this.isSettingsPage = window.location.pathname === '/user_settings';
      this.username = localStorage.getItem('username');
      if (JSON.parse(localStorage.getItem('userDetails')) && JSON.parse(localStorage.getItem('userDetails')).ldap) {
        this.isLoggedLdapUser = true;
      }
      this.$store.dispatch('roles/fetch_all_roles');
      await this.$store.dispatch('users/reset_user');
      this.$refs.form.resetValidation();
      if (!this.isSettingsPage) {
        // eslint-disable-next-line no-restricted-globals
        this.id = location.pathname.substring(7);
      }
      if (this.isSettingsPage || this.id !== 'new') {
        await this.$store.dispatch('users/get_user', this.id || this.selfId);
        this.fullName = `${this.userData.firstName} ${this.userData.lastName}`;
        this.avatarSrc = this.userData.profileImgURL;
      } else {
        this.userData.preferredLanguage = this.languages[0].key;
      }
      this.$refs.firstName.focus();
      if (this.userData && this.userData.ldap) {
        this.ldapUser = true;
      }
    } catch (e) {
      this.$log.debug(e);
    }
  },
  methods: {
    _clearAvatar(event) {
      event.target.value = '';
      this.avatarFile = null;
      this.userData.profileImgURL = '';
    },
    _getAvatarExt(event) {
      return event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();
    },
    _showErrorToast(event, isError, text) {
      if (isError) {
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text,
          color: 'red',
          showClose: true,
        });
        return true;
      }
      return false;
    },
    // eslint-disable-next-line consistent-return
    changeAvatar(event) {
      this.avatarSrc = null;
      if (
        !event.target.files.length ||
        this._showErrorToast(
          event,
          !ALLOWED_EXTENSIONS.includes(this._getAvatarExt(event)),
          'users.addEditUser.wrongImgExtensionMsg',
        ) ||
        this._showErrorToast(
          event,
          event.target.files[0].size > MAX_FILE_SIZE,
          'users.addEditUser.maxImgSizeExceededMsg',
        )
      ) {
        return this._clearAvatar(event);
      }
      // eslint-disable-next-line prefer-destructuring
      this.avatarFile = event.target.files[0];
      this.loadingImage = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.avatarSrc = e.target.result;
        this.loadingImage = false;
      };
      reader.readAsDataURL(this.avatarFile);
    },

    async submitForm() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const hasUserId = !!this.userData.id;
      if (this.id === 'new') {
        await this.$store.dispatch('users/create_user', {
          data: this.userData,
          avatar: this.avatarFile,
        });
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'users.addEditUser.successCreate',
          color: 'success',
          showClose: true,
        });
      } else {
        const route = !this.isSettingsPage ? 'update_user' : 'update_personal_profile';
        await this.$store.dispatch(`users/${route}`, {
          data: this.userData,
          avatar: this.avatarFile,
          permission: this.hasRoleEditPermission,
        });
        this.$store.dispatch('utils/_api_request_handler/show_custom_toast', {
          text: 'users.addEditUser.successUpdate',
          color: 'success',
          showClose: true,
        });
      }

      let resp;
      if (this.isSettingsPage || this.userData.id === this.selfId) {
        resp = this.userData.toJSON();
      } else {
        resp = hasUserId;
      }
      if (resp) {
        this.$store.dispatch('users/update_local_storage', resp);
      }
      this.goToUsers();
    },
    goToUsers() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
#iiotAddEditUserOval,
#iiotAddEditUserAvatarField,
#iiotAddEditUserOval > #iiotAddEditUserAvatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

#iiotAddEditUserOval {
  background-color: #d2d2d2;
  align-items: center;
  justify-content: center;

  > #iiotAddEditUserAvatar {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  > #iiotAddEditUserAvatar,
  input {
    position: absolute;
  }

  input {
    cursor: pointer;
    opacity: 0;
  }

  > div {
    padding: 2.5em;
    text-transform: lowercase;
    word-wrap: break-word;

    .spinner {
      animation-name: spin;
      animation-duration: 4000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
